.app__content {
  padding: var(--op-space-x-large) var(--op-space-medium) var(--op-space-3x-large) var(--op-space-medium);
  display: flex;
  flex-direction: column;
  gap: var(--op-space-medium);
  max-width: var(--op-breakpoint-medium);

  @media screen and (max-width: $breakpoint-large) {
    padding: var(--op-space-large) var(--op-space-small);
    gap: var(--op-space-medium);
  }

  @media screen and (max-width: $breakpoint-x-small) {
    gap: var(--op-space-large);
  }

  .admin & {
    padding: var(--op-space-3x-large) var(--op-space-3x-large);
    max-width: var(--op-breakpoint-large);
  }

  &:has(.side-column, .styled-container--large, .styled-container--x-large) {
    max-width: var(--op-breakpoint-large);
  }

  &:has(.layout-area-three-col) {
    max-width: var(--op-breakpoint-x-large);
  }
}

.admin-page-header {
  display: flex;
  align-items: center;
  gap: var(--op-space-x-small);

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;
    align-items: start;
    gap: var(--op-space-medium);

    .search {
      width: 100%;
    }
  }
}

.page-header {
  display: flex;
  align-items: center;
  gap: var(--op-space-2x-small);

  .page-header__title {
    color: var(--op-color-neutral-on-plus-max);
    font-size: var(--op-font-small);
    font-weight: var(--op-font-weight-semi-bold);
    line-height: var(--op-line-height-dense);
    display: flex;
    gap: var(--op-space-2x-small);

    @media screen and (max-width: $breakpoint-x-small) {
      flex-direction: column;
      gap: var(--op-space-2x-small);
      font-size: var(--op-font-x-large);
      font-weight: var(--op-font-weight-bold);
    }

    &--padding {
      @media screen and (width > $breakpoint-x-small) {
        padding-left: var(--op-space-x-small);
      }
    }

    .page-header__title--large {
      font-size: var(--op-font-large);
      font-weight: var(--op-font-weight-bold);
      line-height: var(--op-line-height-dense);
    }
  }

  &.page-header__medium {
    .page-header__title {
      font-size: var(--op-font-medium);

      @media screen and (max-width: $breakpoint-x-small) {
        font-size: var(--op-font-x-large);
      }
    }
  }
}

.section-header {
  display: flex;
  align-items: center;
  gap: var(--op-space-small);

  [class^="heading"] {
    flex-grow: 1;
  }

  @container (width <= 500px) {
    flex-direction: column;
    align-items: start;
  }
}

.badge-wrapper {
  display: flex;
  align-items: center;
  gap: var(--op-space-x-small);
}

.quick-links {
  position: relative;
  display: inline-block;
  flex: 1 1 auto;
  white-space: nowrap;
  scrollbar-width: none;
  overflow-x: auto;
  overflow-y: hidden;

  .quick-links-wrapper {
    display: flex;
    gap: var(--op-space-3x-small);

    @media screen and (min-width: 895px) {
      justify-content: center;
    }

    &.quick-links-wrapper--spaced {
      gap: var(--op-space-large);

      @media screen and (max-width: $breakpoint-x-small) {
        gap: var(--op-space-x-small);
      }
    }
  }
}

.quick-links__left-nav,
.quick-links__right-nav {
  @media screen and (min-width: 895px) {
    display: none;
  }
}

section.reports-list {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-medium);
}

.two-col-flex-wrap {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: var(--op-space-medium);

  &>* {
    flex: 1 0 calc(50% - var(--op-space-3x-large)/2);
  }

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    flex-wrap: nowrap;

    &>* {
      flex-basis: auto;
    }
  }
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: var(--op-space-large);
  width: 100%;
}

.skip-to-content-link {
  left: 0;
  position: absolute;
  transform: translateY(-100%);
  padding: var(--op-space-small);
  background-color: var(--gc-color-navbar-background);
  color: var(--gc-color-navbar-text);

  &:focus {
    transform: translateY(0%);
    position: relative;
  }
}

.empty-message {
  padding-block: var(--op-space-4x-large);
  font-size: var(--gc-font-small-minus-one);
  border: 1px dotted var(--op-color-border);
  text-align: center;
}

// We should probably create a new layout class to handle this reduction in gap on smaller screens.
@media screen and (max-width: $breakpoint-large) {
  .flexible-layout-area.gap-3xl {
    gap: var(--op-space-large);
  }
}

.app-with-sidebar {
  grid-template-areas:
    'banners banners banners'
    'left-sidebar body right-sidebar';
  grid-template-rows: auto 1fr;

  .app-body {
    grid-area: body;
  }

  .sidebar {
    grid-area: left-sidebar;
  }

  .banners {
    grid-area: banners;
  }
}

