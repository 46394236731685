:root {
  --watchlist-dropdown-width: 20rem;
}

.watchlist {
  &__button-bar {
    background-color: var(--gc-color-background);
    border-radius: var(--gc-radius-default);
    padding: var(--op-space-x-small);
  }

  &__divider {
    margin-bottom: var(--op-space-x-small);
    margin-top: var(--op-space-x-small);
  }

  &-view {
    &__actions {
      display: flex;
      flex-direction: row;
      gap: var(--op-space-x-small);
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
}

.watchlist__dropdown {
  width: var(--watchlist-dropdown-width);
}

.watchlist__dropdown-control {
  align-content: center;
}

.watchlist__dropdown-text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
