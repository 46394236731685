@mixin secondary-semantic-scale {
  & {
    --gc-color-secondary-brand: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 41%);
    --gc-color-secondary-plus-max: hsl(0 0% 0%);
    --gc-color-secondary-plus-eight: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 4%);
    --gc-color-secondary-plus-seven: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 8%);
    --gc-color-secondary-plus-six: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 12%);
    --gc-color-secondary-plus-five: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 14%);
    --gc-color-secondary-plus-four: hsl(var(--gc-color-secondary-h) var (--gc-color-secondary-s) 16%);
    --gc-color-secondary-plus-three: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 20%);
    --gc-color-secondary-plus-two: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 24%);
    --gc-color-secondary-plus-one: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 28%);
    --gc-color-secondary-base: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 40%);
    --gc-color-secondary-minus-one: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 36%);
    --gc-color-secondary-minus-two: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 40%);
    --gc-color-secondary-minus-three: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 48%);
    --gc-color-secondary-minus-four: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 52%);
    --gc-color-secondary-minus-five: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 64%);
    --gc-color-secondary-minus-six: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 72%);
    --gc-color-secondary-minus-seven: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 80%);
    --gc-color-secondary-minus-eight: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 88%);
    --gc-color-secondary-minus-max: hsl(0 0% 100%);
    --gc-color-secondary-on-plus-max: hsl(0 0% 100%);
    --gc-color-secondary-on-plus-max-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 88%);
    --gc-color-secondary-on-plus-eight: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 94%);
    --gc-color-secondary-on-plus-eight-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 82%);
    --gc-color-secondary-on-plus-seven: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 90%);
    --gc-color-secondary-on-plus-seven-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 80%);
    --gc-color-secondary-on-plus-six: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 92%);
    --gc-color-secondary-on-plus-six-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 72%);
    --gc-color-secondary-on-plus-five: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 92%);
    --gc-color-secondary-on-plus-five-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 72%);
    --gc-color-secondary-on-plus-four: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 94%);
    --gc-color-secondary-on-plus-four-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 80%);
    --gc-color-secondary-on-plus-three: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 98%);
    --gc-color-secondary-on-plus-three-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 78%);
    --gc-color-secondary-on-plus-two: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 98%);
    --gc-color-secondary-on-plus-two-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 88%);
    --gc-color-secondary-on-plus-one: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 5%);
    --gc-color-secondary-on-plus-one-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 2%);
    --gc-color-secondary-on-base: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 8%);
    --gc-color-secondary-on-base-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 0%);
    --gc-color-secondary-on-minus-one: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 2%);
    --gc-color-secondary-on-minus-one-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 7%);
    --gc-color-secondary-on-minus-two: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 2%);
    --gc-color-secondary-on-minus-two-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 10%);
    --gc-color-secondary-on-minus-three: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 2%);
    --gc-color-secondary-on-minus-three-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 18%);
    --gc-color-secondary-on-minus-four: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 2%);
    --gc-color-secondary-on-minus-four-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 20%);
    --gc-color-secondary-on-minus-five: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 6%);
    --gc-color-secondary-on-minus-five-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 20%);
    --gc-color-secondary-on-minus-six: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 2%);
    --gc-color-secondary-on-minus-six-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 16%);
    --gc-color-secondary-on-minus-seven: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 2%);
    --gc-color-secondary-on-minus-seven-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 26%);
    --gc-color-secondary-on-minus-eight: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 2%);
    --gc-color-secondary-on-minus-eight-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 24%);
    --gc-color-secondary-on-minus-max: hsl(0 0% 0%);
    --gc-color-secondary-on-minus-max-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 28%);
  }
}

@mixin dark-borders {
  & {
    --op-color-border: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 35%);
  }
}

@mixin dark-mode-color-tokens {
  & {
    // color over-rides for dark mode
    // make primary slightly more saturated in dark mode
    --op-color-primary-s: 50%;
    // turn neutral to primary hue and saturation since it visually clashes with primary when it's in dark mode`
    --op-color-neutral-h: 227;
    --op-color-neutral-s: 24%;
    --op-color-primary-on-plus-one: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 90%);
    --op-color-primary-on-plus-five: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 90%);
    --op-color-neutral-on-plus-max: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 85%);
    --op-color-neutral-on-plus-max-alt: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 65%);
    --op-color-neutral-on-plus-eight: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 85%);

    --gc-color-logo-text: white;
    --gc-color-background: var(--gc-color-background-dark);
    --gc-color-dark-text: hsl(120, 4%, 80%);
    --gc-color-alerts-danger-custom: hsl(28 54% 60%);
    --gc-color-navbar-text: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 90%);
    --gc-color-navbar-background: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 10%);

    --op-color-border: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 80%);
    --gc-text-meta: var(--op-color-neutral-on-plus-max-alt);
    --gc-text-color: var(--op-color-neutral-on-plus-max);
    --gc-relationship-manager-border: hsl(var(--op-color-primary-h) 60% 70%);
    --gc-color-brand-text: var(--gc-color-dark-text);

    --gc-heat-map-high: var(--op-color-alerts-info-minus-six);
    --gc-heat-map-low: var(--op-color-alerts-info-plus-seven);
  }
}

@mixin dark-mode-button-colors {
  & {
    // default button
    --gc-btn-border: var(--op-color-neutral-minus-three);

    // Hover states
    --gc-btn-hover-text: var(--gc-color-secondary-on-minus-max);

    // Focus States (shared by all buttons)
    --gc-btn-focus-background: var(--op-color-primary-plus-seven);
    --gc-btn-focus-inner: var(--op-color-primary-minus-five);
    --gc-btn-focus-outer: var(--op-color-primary-minus-four);
    --gc-btn-focus-text: var(--op-color-primary-on-base);

    // Primary button
    --gc-btn-primary-background: var(--op-color-primary-minus-one);
    --gc-btn-primary-border: var(--op-color-primary-minus-one);
    --gc-btn-primary-text: var(--op-color-primary-on-base);

    // Borderless button
    --gc-btn--no-border-active-background: var(--op-color-primary-plus-two);
    --gc-btn--no-border-active-border: var(--op-color-primary-minus-five);
    --gc-btn--no-border-active-text: var(--op-color-primary-on-plus-two-alt);

    // Borderless primary button
    --gc-btn-primary--no-border-text: var(--op-color-primary-minus-seven);
  }
}

@mixin dark-mode-link-colors {
  & {
    // links
    --gc-link-hover-color: hsl(var(--op-color-primary-h) 70% 70%);
    --gc-link-primary-color: hsl(var(--op-color-primary-h) 70% 70%);
  }
}

@mixin dark-inputs {
  & {
    --gc-input-inner-focus-color: var(--op-color-primary-minus-five);
    --gc-input-outer-focus-color: var(--op-color-primary-minus-four);
    --op-encoded-images-search-icon: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAnIGhlaWdodD0nMjAnIHZpZXdCb3g9JzAgMCAyMCAyMCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTcuMTUgMTguMkwxMC44NSAxMS45MjVDMTAuMzUgMTIuMzQxNyA5Ljc3NSAxMi42NjY3IDkuMTI1IDEyLjlDOC40NzUgMTMuMTMzMyA3LjgwODMzIDEzLjI1IDcuMTI1IDEzLjI1QzUuNDA4MzMgMTMuMjUgMy45NTgzMyAxMi42NTgzIDIuNzc1IDExLjQ3NUMxLjU5MTY3IDEwLjI5MTcgMSA4Ljg0MTY3IDEgNy4xMjVDMSA1LjQyNSAxLjU5MTY3IDMuOTgzMzMgMi43NzUgMi44QzMuOTU4MzMgMS42IDUuNDA4MzMgMSA3LjEyNSAxQzguODI1IDEgMTAuMjY2NyAxLjU5MTY3IDExLjQ1IDIuNzc1QzEyLjYzMzMgMy45NTgzMyAxMy4yMjUgNS40MDgzMyAxMy4yMjUgNy4xMjVDMTMuMjI1IDcuODQxNjcgMTMuMTA4MyA4LjUyNSAxMi44NzUgOS4xNzVDMTIuNjQxNyA5LjgyNSAxMi4zMjUgMTAuMzkxNyAxMS45MjUgMTAuODc1TDE4LjIgMTcuMTVMMTcuMTUgMTguMlpNNy4xMjUgMTEuNzVDOC40MDgzMyAxMS43NSA5LjQ5MTY3IDExLjMgMTAuMzc1IDEwLjRDMTEuMjc1IDkuNSAxMS43MjUgOC40MDgzMyAxMS43MjUgNy4xMjVDMTEuNzI1IDUuODQxNjcgMTEuMjc1IDQuNzUgMTAuMzc1IDMuODVDOS40OTE2NyAyLjk1IDguNDA4MzMgMi41IDcuMTI1IDIuNUM1LjgyNSAyLjUgNC43MjUgMi45NSAzLjgyNSAzLjg1QzIuOTQxNjcgNC43NSAyLjUgNS44NDE2NyAyLjUgNy4xMjVDMi41IDguNDA4MzMgMi45NDE2NyA5LjUgMy44MjUgMTAuNEM0LjcyNSAxMS4zIDUuODI1IDExLjc1IDcuMTI1IDExLjc1WicgZmlsbD0nI0QwRDNFMicvPjwvc3ZnPg==");
  }
}

@mixin dark-striped-table {
  & {
    --gc-table-zebra-background: hsla(var(--op-color-primary-h), 15%, 24%, 1);
  }
}

@mixin all-dark-mode-tokens {
  @include secondary-semantic-scale;
  @include dark-mode-color-tokens;
  @include dark-mode-button-colors;
  @include dark-mode-link-colors;
  @include dark-borders;
  @include dark-inputs;
  @include dark-striped-table;
}

@media(prefers-color-scheme: dark) {
  :root:not([data-theme-mode='light']) {
    @include all-dark-mode-tokens;
    & {
      color-scheme: dark;
    }
  }
}

:root[data-theme-mode='dark'] {
  @include all-dark-mode-tokens;
  & {
    color-scheme: dark;
  }
}
