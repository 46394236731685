// a class that can be included to style containers that will give them a black box shadow and set them to flex with proper padding and gap.
%styled-container {
  --_gc-container-padding-default: var(--op-space-2x-large);
  --_gc-container-padding-x-small: var(--op-space-large);
  --_gc-container-padding-small: var(--op-space-x-large);
  --_gc-container-padding-large: var(--gc-space-2x-larger);
  --_gc-container-padding-x-large: var(--op-space-3x-large);

  --__gc-container-padding: var(--_gc-container-padding-default);

  color: var(--gc-text-color);
  box-shadow: var(--op-border-all) var(--op-color-border);
  background: var(--gc-color-container-background);
  display: flex;
  gap: var(--op-space-medium);
  padding: var(--__gc-container-padding);
  flex-direction: column;
  width: 100%;
  align-self: flex-start;
  border-radius: var(--gc-radius-default);

  transition: all 200ms ease;


  &.styled-container--x-small {
    --__gc-container-padding: var(--_gc-container-padding-x-small);
  }

  &.styled-container--small {
    --__gc-container-padding: var(--_gc-container-padding-small);
  }

  &.styled-container--large {
    --__gc-container-padding: var(--_gc-container-padding-large);
    gap: var(--op-space-small)
  }

  &.styled-container--x-large {
    --__gc-container-padding: var(--_gc-container-padding-x-large);
  }

  @media screen and (max-width: $breakpoint-x-small) {
    gap: var(--op-space-large);
    border-radius: 0;
  }

  @media (width <=$breakpoint-medium) {
    --__gc-container-padding: var(--_gc-container-padding-small);

    &.styled-container--x-small,
    &.styled-container--small,
    &.styled-container--large,
    &.styled-container--x-large {
      --__gc-container-padding: var(--_gc-container-padding-small);
    }
  }

  @media (width <=$breakpoint-x-small) {
    --__gc-container-padding: 0;
    box-shadow: none;

    &.styled-container--x-small,
    &.styled-container--small,
    &.styled-container--large,
    &.styled-container--x-large {
      --__gc-container-padding: 0;
    }
  }

  &.styled-container--spaced-x-large-mobile {
    @media screen and (max-width: $breakpoint-x-small) {
      gap: var(--op-space-x-large);
    }
  }

  &.styled-container--separator-bottom-mobile {
    @media screen and (width < $breakpoint-x-small) {
      border-bottom: 1px solid var(--op-color-neutral-plus-four);
      padding-bottom: var(--gc-separated-spacing);
    }
  }

  // used to create nesting of items inside a box
  .styled-container__group {
    --_gc-container-justify: flex-start;
    --_gc-container-align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: var(--_gc-container-justify);
    align-items: var(--_gc-container-align-items);
    gap: var(--op-space-3x-small);

    // the current design sometimes centers items in the box
    &.styled-container__group--centered {
      --_gc-container-justify: center;
      --_gc-container-align-items: center;
      text-align: center;

      @media (width <=$breakpoint-x-small) {
        --_gc-container-align-items: flex-start;
        text-align: left;
      }
    }

    &.styled-container__group--flex-start-mobile {
      @media (width <= $breakpoint-x-small) {
        --_gc-container-justify: flex-start;
      }
    }

    &.styled-container__group-spaced {
      gap: var(--op-space-medium)
    }

    &.styled-container__group-spaced-large {
      gap: var(--op-space-large)
    }
  }

  .styled-container__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: stretch;
    border-radius: var(--op-radius-small);

    .link {
      font-size: var(--op-font-small);
      line-height: var(--op-line-height-loosest);
    }

    &.flex-row {
      flex-direction: row;
    }

    &.desktop-only {
      @media screen and (max-width: $breakpoint-x-small) {
        display: none;
      }
    }

    @media screen and (max-width: $breakpoint-small) {
      align-items: flex-start;
    }
  }

  .styled-container__group-title {
    @include section-title;

    &.styled-container__group-title-small {
      font-size: var(--op-font-small);
    }
  }

  .styled-container__group-text-content {
    font-size: var(--gc-font-small-minus-one);
    color: var(--gc-color-dark-text);
    font-weight: var(--op-font-weight-normal);
    line-height: var(--op-line-height-base);
  }

  .styled-container__text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--op-space-x-small);
    align-self: stretch;
    color: var(--op-color-neutral-on-plus-max);
    font-size: var(--op-font-x-small);
    font-style: normal;
    font-weight: var(--op-font-weight-normal);
    line-height: var(--op-line-height-base);
  }

  &.gap-lg {
    gap: var(--op-space-large);
  }
}

.styled-container {
  @extend %styled-container;
  container: main-container / inline-size;

  .side-column & {
    container: aside-container / inline-size;
  }
}
