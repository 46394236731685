// A theme comprised of a primary, neutral, warning, danger, info, and notice colors.

$select-color-input: var(--op-color-neutral-plus-max);
$select-border-radius: var(--gc-radius-default);

@mixin color-varieties {
  & {
    // Basic Colors

    --op-color-white: hsl(0deg 100% 100%);
    --op-color-black: hsl(0deg 0% 0%);

    --op-color-primary-h: 228;
    --op-color-primary-s: 42%;
    --op-color-primary-l: 14%;

    --op-color-neutral-h: 0;
    --op-color-neutral-s: 0%;
    --op-color-neutral-l: 96%;

    --op-color-alerts-warning-h: 54;
    --op-color-alerts-warning-s: 96%;
    --op-color-alerts-warning-l: 41%;

    --op-color-alerts-danger-h: 14;
    --op-color-alerts-danger-s: 96%;
    --op-color-alerts-danger-l: 41%;

    --op-color-alerts-info-h: 230;
    --op-color-alerts-info-s: 96%;
    --op-color-alerts-info-l: 41%;

    --op-color-alerts-notice-h: 100;
    --op-color-alerts-notice-s: 96%;
    --op-color-alerts-notice-l: 41%;
  }
}

@mixin secondary-color {
  & {
    --gc-color-secondary-h: 78;
    --gc-color-secondary-s: 96%;
    --gc-color-secondary-l: 41%;
  }
}

@mixin secondary-semantic-scale {
  & {
    --gc-color-secondary-original: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) var(--gc-color-secondary-l));

    // Main Scale
    --gc-color-secondary-plus-max: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 100%);
    --gc-color-secondary-plus-eight: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 98%);
    --gc-color-secondary-plus-seven: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 96%);
    --gc-color-secondary-plus-six: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 94%);
    --gc-color-secondary-plus-five: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 90%);
    --gc-color-secondary-plus-four: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 84%);
    --gc-color-secondary-plus-three: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 70%);
    --gc-color-secondary-plus-two: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 64%);
    --gc-color-secondary-plus-one: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 45%);
    --gc-color-secondary-base: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 40%);
    --gc-color-secondary-minus-one: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 36%);
    --gc-color-secondary-minus-two: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 32%);
    --gc-color-secondary-minus-three: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 28%);
    --gc-color-secondary-minus-four: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 24%);
    --gc-color-secondary-minus-five: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 20%);
    --gc-color-secondary-minus-six: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 16%);
    --gc-color-secondary-minus-seven: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 8%);
    --gc-color-secondary-minus-eight: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 4%);
    --gc-color-secondary-minus-max: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 0%);

    // On Scale
    --gc-color-secondary-on-plus-max: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 0%);
    --gc-color-secondary-on-plus-max-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 20%);
    --gc-color-secondary-on-plus-eight: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 4%);
    --gc-color-secondary-on-plus-eight-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 24%);
    --gc-color-secondary-on-plus-seven: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 8%);
    --gc-color-secondary-on-plus-seven-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 28%);
    --gc-color-secondary-on-plus-six: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 16%);
    --gc-color-secondary-on-plus-six-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 26%);
    --gc-color-secondary-on-plus-five: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 20%);
    --gc-color-secondary-on-plus-five-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 40%);
    --gc-color-secondary-on-plus-four: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 24%);
    --gc-color-secondary-on-plus-four-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 4%);
    --gc-color-secondary-on-plus-three: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 20%);
    --gc-color-secondary-on-plus-three-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 10%);
    --gc-color-secondary-on-plus-two: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 16%);
    --gc-color-secondary-on-plus-two-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 6%);
    --gc-color-secondary-on-plus-one: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 100%);
    --gc-color-secondary-on-plus-one-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 95%);
    --gc-color-secondary-on-base: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 8%);
    --gc-color-secondary-on-base-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 88%);
    --gc-color-secondary-on-minus-one: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 94%);
    --gc-color-secondary-on-minus-one-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 82%);
    --gc-color-secondary-on-minus-two: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 90%);
    --gc-color-secondary-on-minus-two-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 78%);
    --gc-color-secondary-on-minus-three: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 86%);
    --gc-color-secondary-on-minus-three-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 74%);
    --gc-color-secondary-on-minus-four: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 84%);
    --gc-color-secondary-on-minus-four-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 72%);
    --gc-color-secondary-on-minus-five: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 88%);
    --gc-color-secondary-on-minus-five-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 78%);
    --gc-color-secondary-on-minus-six: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 94%);
    --gc-color-secondary-on-minus-six-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 82%);
    --gc-color-secondary-on-minus-seven: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 96%);
    --gc-color-secondary-on-minus-seven-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 84%);
    --gc-color-secondary-on-minus-eight: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 98%);
    --gc-color-secondary-on-minus-eight-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 86%);
    --gc-color-secondary-on-minus-max: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 100%);
    --gc-color-secondary-on-minus-max-alt: hsl(var(--gc-color-secondary-h) var(--gc-color-secondary-s) 88%);
  }
}

@mixin custom-colors {
  & {
    --gc-color-container-background: var(--op-color-neutral-plus-max);
    --gc-color-background: hsl(240, 20%, 95%); // approved brand gray
    --gc-color-background-dark: hsl(240 20% 5%);
    --gc-color-background-light: hsl(0 0% 100%);
    --gc-color-alerts-danger-custom: hsl(30, 50%, 40%);
    --gc-color-dark-text: hsl(120, 4%, 5%);
    --gc-color-brand-text: var(--op-color-primary-original);
    --gc-text-meta: hsl(0, 0%, 60%);
    --gc-text-color: var(--op-color-neutral-on-plus-max);
    --gc-relationship-manager-border: hsl(var(--op-color-primary-h) 50% 70%);
    --gc-heroku-background: linear-gradient(180deg, hsl(227 57% 32%) 0%, hsl(227 42% 14%) 100%);

    --gc-heat-map-high: var(--op-color-alerts-info-base);
    --gc-heat-map-low: var(--op-color-alerts-info-plus-max);
  }
}

@mixin navbar-colors {
  & {
    // navbar
    --gc-color-navbar-background: var(--op-color-primary-original);
    --gc-color-navbar-text: hsl(0, 0%, 100%);
    --gc-color-logo-text: var(--op-color-primary-original);
    --gc-color-logo-text-reverse: hsl(0, 0%, 100%);
  }
}

@mixin button-colors {
  & {
    // default button
    --gc-btn-background: var(--op-color-neutral-plus-max);
    --gc-btn-border: var(--op-color-border);
    --gc-btn-text: var(--op-color-neutral-on-plus-max);

    // Hover States
    --gc-btn-hover-background: var(--gc-color-secondary-original);
    --gc-btn-hover-border: var(--gc-color-secondary-original);
    --gc-btn-hover-text: var(--gc-color-secondary-on-base);

    // Focus States (shared by all buttons)
    --gc-btn-focus-background: var(--op-color-primary-minus-one);
    --gc-btn-focus-inner: var(--op-color-primary-plus-one);
    --gc-btn-focus-outer: var(--op-color-primary-plus-three);
    --gc-btn-focus-text: var(--op-color-primary-on-minus-one);

    // Primary button
    --gc-btn-primary-background: var(--op-color-primary-minus-seven);
    --gc-btn-primary-border: var(--op-color-primary-minus-seven);
    --gc-btn-primary-text: var(--op-color-primary-on-minus-seven);
    --gc-btn-primary-hover-background: var(--gc-color-secondary-original);
    --gc-btn-primary-hover-border: var(--gc-color-secondary-original);
    --gc-btn-primary-hover-text: var(--gc-color-secondary-on-base);

    // Borderless button
    --gc-btn--no-border-background: transparent;
    --gc-btn--no-border-border: transparent;
    --gc-btn--no-border-text: var(--op-color-neutral-on-plus-max);
    --gc-btn--no-border-hover-background: var(--gc-color-secondary-original);
    --gc-btn--no-border-hover-border: var(--gc-color-secondary-original);
    --gc-btn--no-border-hover-text: var(--gc-color-secondary-on-base);
    --gc-btn--no-border-active-background: var(--op-color-primary-plus-five);
    --gc-btn--no-border-active-border: var(--op-color-primary-plus-two);
    --gc-btn--no-border-active-text: var(--op-color-primary-on-plus-five);

    // Borderless primary button
    --gc-btn-primary--no-border-background: transparent;
    --gc-btn-primary--no-border-border: transparent;
    --gc-btn-primary--no-border-text: var(--op-color-primary-plus-one);
    --gc-btn-primary--no-border-hover-background: var(--gc-color-secondary-original);
    --gc-btn-primary--no-border-hover-border: var(--gc-color-secondary-original);
    --gc-btn-primary--no-border-hover-text: var(--gc-color-secondary-on-base);
  }
}

@mixin link-colors {
  & {
    // links
    --gc-link-color: var(--gc-text-color);
    --gc-link-primary-color: hsl(207, 74%, 42%);
    --gc-link-hover-color: hsl(207, 74%, 42%);
  }
}

@mixin form-overrides {
  & {
    --gc-input-placeholder-text-color: var(--op-color-neutral-on-plus-max-alt);
    --gc-input-inner-focus-color: var(--op-color-primary-plus-one);
    --gc-input-outer-focus-color: var(--op-color-primary-plus-three);
    --op-input-outer-focus: 0 0 0 var(--op-border-width-large);
    --op-input-inner-focus: inset 0 0 0 var(--op-border-width);
    --op-input-focus-primary: var(--op-input-inner-focus) var(--gc-input-inner-focus-color),
      var(--op-input-outer-focus) var(--gc-input-outer-focus-color);
  }

  @media (width < $breakpoint-x-small) {
    & {
      --op-input-height-large: 4.6rem;
    }
  }
}

@mixin color-overrides {
  & {
    --op-color-border: var(--gc-text-meta);
    --op-color-neutral-on-plus-max: hsl(0, 0%, 20%);
  }
}

@mixin border-radius {
  & {
    --gc-radius-default: 4px;
  }
}

@mixin striped-table {
  & {
    --gc-table-zebra-background: hsla(var(--op-color-primary-h), 15%, 92%, 1);
  }
}

@mixin avatar {
  & {
    --gc-avatar-background: hsl(227, 56%, 8%);
  }
}

@mixin transitions {
  & {
    --gc-transition-menu: all 300ms ease-in-out;
    --gc-transition-search: all 200ms ease-in-out;
    --gc-transition-btn-hover: all 200ms ease-in-out;
  }
}

@mixin shadows {
  & {
    --op-shadow-x-small: 0 2px 6px hsl(230deg 73% 12% / 4%);
    --op-shadow-small:
      0px 1px 1px hsla(var(--op-color-primary-h), 100%, 14%, 0.05),
      0px 3px 4px hsla(var(--op-color-primary-h), 100%, 14%, 0.04),
      0px 7px 8px hsla(var(--op-color-primary-h), 100%, 14%, 0.03),
      0px 13px 15px hsla(var(--op-color-primary-h), 100%, 14%, 0.02),
      0px 20px 23px hsla(var(--op-color-primary-h), 100%, 14%, 0.01);
    --op-shadow-medium:
      0px 2px 2px hsla(var(--op-color-primary-h), 100%, 14%, 0.05),
      0px 7px 6px hsla(var(--op-color-primary-h), 100%, 14%, 0.04),
      0px 17px 14px hsla(var(--op-color-primary-h), 100%, 14%, 0.03),
      0px 29px 26px hsla(var(--op-color-primary-h), 100%, 14%, 0.02),
      0px 46px 40px hsla(var(--op-color-primary-h), 100%, 14%, 0.01);
    --op-shadow-large: 0 8px 18px hsl(230deg 73% 12% / 4%);
    --op-shadow-x-large: 0 12px 22px hsl(230deg 73% 12% / 5%);
  }
}

@mixin spacing-scales {
  & {
    --op-space-2x-small: calc(var(--op-space-scale-unit) * 0.5);
    --op-space-2x-large: calc(var(--op-space-scale-unit) * 3); // 30px
    --gc-space-2x-larger: calc(var(--op-space-scale-unit) * 3.6); // 36px
  }
}

@mixin breakpoints-overrides {
  & {
    --op-breakpoint-x-large: 1294px;
    --op-breakpoint-large: 1130px;
    --op-breakpoint-medium: 900px;
  }
}

@mixin brand {
  & {
    --gc-brand-top-width: 7px;
    --gc-brand-tag-width: 2px;
  }
}

@mixin separator {
  & {
    --gc-separated-spacing: var(--op-space-medium);
  }
}

$breakpoint-large: 1130px; // small laptop

:root {
  @include border-radius;
  // Color HSLs
  @include secondary-color;
  @include secondary-semantic-scale;
  @include color-varieties;
  @include color-overrides;
  @include custom-colors;
  @include navbar-colors;
  @include button-colors;
  @include link-colors;
  @include form-overrides;
  @include transitions;
  @include shadows;
  @include spacing-scales;
  @include striped-table;
  @include avatar;
  @include breakpoints-overrides;
  @include brand;
  @include separator;
}
