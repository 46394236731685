// This is a manifest file that'll be compiled into application.css, which will include
// all the files listed below.
//
// For example:
// @import 'components/modal';
@import '@rolemodel/optics/dist/scss/optics';

// Theme Customization
@import 'core/theme/gimme-credit-theme';
@import 'core/theme/gimme-credit-dark-theme';
@import 'core/base';
@import 'core/layout';

// Vendor
@import 'tom-select/dist/scss/tom-select';
@import '@rolemodel/optics/dist/scss/addons/tom-select';

// Optics Overrides
@import 'components/optics-overrides/accordion';
@import 'components/optics-overrides/alert';
@import 'components/optics-overrides/avatar';
@import 'components/optics-overrides/badge.scss';
@import 'components/optics-overrides/breadcrumbs';
@import 'components/optics-overrides/button';
@import 'components/optics-overrides/card';
@import 'components/optics-overrides/divider';
@import 'components/optics-overrides/form';
@import 'components/optics-overrides/icon';
@import 'components/optics-overrides/modal';
@import 'components/optics-overrides/pagination';
@import 'components/optics-overrides/sidebar';
@import 'components/optics-overrides/tab';
@import 'components/optics-overrides/table';
@import 'components/optics-overrides/text-pair';

// Vendor Overrides
@import 'components/flatpickr/index.scss';
@import 'components/shoelace/index.scss';
@import 'components/custom-tom-select';

// Components
// Needs to be defined first because it defines a mixin
@import 'components/separator';
@import 'components/typography';

@import 'components/analyst';
@import 'components/analytic';
@import 'components/archive';
@import 'components/company';
@import 'components/charts';
@import 'components/contact';
@import 'components/credit-score';
@import 'components/data-sheet';
@import 'components/details-sheet';
@import 'components/distribution';
@import 'components/download';
@import 'components/dropdown';
@import 'components/faq';
@import 'components/feedback';
@import 'components/footer';
@import 'components/heroku';
@import 'components/hubspot_sync_request';
@import 'components/link';
@import 'components/login';
@import 'components/logo';
@import 'components/mt_newswires';
@import 'components/navbar';
@import 'components/news';
@import 'components/notification';
@import 'components/notes.scss';
@import 'components/paid-time-offs';
@import 'components/privacy';
@import 'components/products';
@import 'components/profile';
@import 'components/publisher_notes';
@import 'components/relationship_manager';
@import 'components/report';
@import 'components/research';
@import 'components/side-column';
@import 'components/schedule';
@import 'components/search';
@import 'components/styled-container';
@import 'components/test-banner';
@import 'components/user';
@import 'components/utilities';
@import 'components/variables';
@import 'components/watchlist';

// Other
@import 'actiontext';
@import 'print';
@import 'native_bridge';
@import 'flatpickr/dist/flatpickr.css';
