@use "sass:math";

:root {
  --gc-half-width: 50%;
  --gc-one-third-width: calc(100% * 1 / 3);
}

.margin-x-auto,
.margin-left-auto {
  margin-left: auto;
}

.margin-x-auto,
.margin-right-auto {
  margin-right: auto;
}

.margin-y-auto,
.margin-top-auto {
  margin-top: auto;
}

.margin-y-auto,
.margin-bottom-auto {
  margin-bottom: auto
}

.font-style-italic {
  font-style: italic;
}

.font-weight-bold {
  font-weight: var(--op-font-weight-bold);
}

@media screen and (max-width: $breakpoint-medium) {
  .flex-mobile-col {
    flex-direction: column;
  }
}

.flex-100 {
  flex: 100%;

  @media screen and (max-width: $breakpoint-small) {
    flex-basis: auto;
  }
}

@media screen and (max-width: $breakpoint-small) {
  .flexible-layout-area {
    flex-wrap: nowrap;

    &.flex-mobile-col-reverse {
      flex-direction: column-reverse;
      align-items: center;

      .mobile-card-width {
        max-width: calc(var(--op-size-unit) * 100);
      }
    }
  }

  .flexible-layout-area:not(.flex-no-wrap)>* {
    flex-basis: auto;
  }
}

.flex-50>* {
  flex: 50%;
}

.flex-33>* {
  flex: calc(100% / 3);
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-share {
  flex: 1 1 0px;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.gap-3xs {
  gap: var(--op-space-3x-small);

  --op-gap: var(--op-space-3x-small);
}

.gap-2xs {
  gap: var(--op-space-2x-small);

  --op-gap: var(--op-space-2x-small);
}

.gap-2xl {
  gap: var(--op-space-2x-large);

  --op-gap: var(--op-space-2x-large);
}

.gap-3xl {
  gap: var(--op-space-3x-large);

  --op-gap: var(--op-space-3x-large);
}

.nowrap {
  white-space: nowrap;
}

.letter {
  width: var(--letter-width) !important;
  height: auto !important;
  padding: .2in var(--letter-width-padding) !important;

  font-size: var(--op-font-small);
  line-height: var(--op-line-height-dense);

  &.letter-height {
    padding: var(--letter-padding) !important;
    height: var(--letter-height) !important;

    &.letter-landscape {
      height: var(--letter-width) !important;
    }

    &.form-control {
      height: calc(var(--letter-height) - var(--report-header-print-height)) !important;
    }

    &.no-padding {
      padding: 0 !important;
    }
  }

  &.letter-landscape {
    width: var(--letter-height) !important;
  }

  &.form-control {
    font-size: var(--op-font-small);
    line-height: var(--op-line-height-dense);
  }

  &.no-padding {
    padding: 0 !important;
  }
}

.action-text-field {
  height: 5in !important;
}

.display-none {
  display: none !important;
}

.visually-hidden {
  visibility: hidden;
  opacity: 0;
}

.flex-col {
  display: flex;
  flex-direction: column;

  &.navbar__mobile-only-nav-item {
    display: none;

    @media screen and (max-width: $breakpoint-large) {
      display: flex;
    }
  }

  @media screen and (max-width: $breakpoint-large) {
    &--reverse-mobile-only {
      flex-direction: column-reverse;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.inline-flex {
  display: inline-flex;
}

.toggle {
  display: none;

  &.active {
    display: block;
  }
}

.relative {
  position: relative;
}

.padding-y-start-xs {
  padding-block-start: var(--op-space-x-small);
}

.padding-y-start-md {
  padding-block-start: var(--op-space-medium);
}

.padding-y-lg {
  padding-block: var(--op-space-large);
}

.padding-x-lg {
  padding-inline: var(--op-space-large);
}

.padding-x-start-xs {
  padding-inline-start: var(--op-space-x-small);
}

.padding-x-start-sm {
  padding-inline-start: var(--op-space-small);
}

.padding-x-end-xs {
  padding-inline-end: var(--op-space-x-small);
}

.padding-bottom-none {
  padding-bottom: 0px !important;
}

.padding-none {
  padding: 0px !important;
}

.on-top {
  top: 0px;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-items-start {
  align-items: flex-start;
}

.tab-group.full-width-background {
  justify-content: center;
  background-color: var(--op-color-background);
}

.column-label {
  gap: var(--op-space-2x-small);
  justify-content: center;
  align-items: center;
  display: flex;
  line-height: normal;
}

.grid-layout-area {
  display: grid;

  &.three-columns {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.clamp {
  &::after {
    content: '…';
  }
}

.divider-with-content {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: stretch;

  @media (width <=$breakpoint-small) {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .divider-vertical {
      height: var(--op-border-width);
      width: 100%;
      min-height: var(--op-border-width);
    }
  }
}

.mobile-only {
  @media screen and (width > $breakpoint-x-small) {
    display: none;
  }

  @media print {
    display: none;
  }
}

.tablet-only {  
  @media screen and (width < $breakpoint-x-small) {
    display: none;
  }
  
  @media screen and (width > $breakpoint-medium) {
    display: none;
  }
}

.desktop-only {
  @media screen and (max-width: $breakpoint-x-small) {
    display: none;
  }
}

.except-mobile {
  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }
}

.except-tablet {
  @media screen and (min-width: $breakpoint-x-small) and (max-width: $breakpoint-medium) {
    display: none;
  }
}

.no-wrap {
  white-space: nowrap;
}

.fetch-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.hide-no-content:not(:has(*)) {
  display: none;
}

.no-padding {
  padding: 0px;
}

.light-only {
  @media(prefers-color-scheme: dark) {
    display: none;
  }
}

.dark-only {
  @media(prefers-color-scheme: light) {
    display: none;
  }
}