.news-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: var(--op-space-3x-large);
  @media screen and (max-width: $breakpoint-small) {
    grid-template-columns: auto;
  }
}

.news-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--op-space-3x-small);

  &__publisher {
    @include meta-text;
    flex: 1 0 0;
    font-size: var(--op-font-small);
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @container aside-container (inline-size < 280px) {
      flex-direction: column-reverse;
      gap: var(--op-space-3x-small);
      .report__date {
        text-align: left;
      }
    }
  }

  &__date {
    @include meta-text;
    white-space: nowrap;
    text-align: right;
    flex: 1;
    font-size: var(--gc-font-small-minus-one);
  }

  &__title-group {
    display: flex;
    align-items: baseline;
    gap: var(--op-space-3x-small) var(--op-space-2x-small);
    align-self: stretch;
    flex-wrap: wrap;
    @container (max-width: 450px) {
      flex-direction: column-reverse;
    }
  }

  &__title {
    align-self: stretch;
    font-size: var(--op-font-small);
    font-weight: var(--op-font-weight-semi-bold);
    line-height: var(--op-line-height-dense);

    .news-item__title-link {
      @media screen and (max-width: $breakpoint-x-small) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: var(--op-font-large);
        font-weight: var(--op-font-weight-medium);
      }
    }
  }

  &__summary {
    @include report-body;
  }
}

aside {
  .news-item {

    &__title,
    &__date,
    &__publisher,
    &__summary {
      font-size: var(--op-font-x-small);
    }
  }
}

.news__view-all {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
}
