%text-pair-global {
  --_op-text-pair-font-size-2x-large: var(--op-font-2x-large);
  --_op-text-pair-font-size-x-large: var(--op-font-x-large);
  --_op-text-pair-font-size-x-small: var(--op-font-x-small);

  // Gimme Credit aligns most things to the baseline. Following that pattern here.
  &.text-pair--inline {
    align-items: baseline;
  }
  &.text-pair--inline-wrap {
    display: block;
    line-height: var(--op-line-height-densest);

    .text-pair__title {
      display: inline;
      padding-right: var(--op-space-3x-small);
    }

    .text-pair__subtitle {
      display: inline;
    }
  }

  &.text-pair--close {
    gap: var(--op-space-3x-small);
  }

  .text-pair__title {
    line-height: var(--op-line-height-base);
    font-weight: var(--op-font-weight-semi-bold);

    a {
      color: inherit;
    }

    &.text-pair__title--2x-large {
      --__op-text-pair-title-font-size: var(--_op-text-pair-font-size-2x-large);
    }

    &.text-pair__title--x-large {
      --__op-text-pair-title-font-size: var(--_op-text-pair-font-size-2x-large);
    }

    &.text-pair__title--x-small {
      --__op-text-pair-title-font-size: var(--_op-text-pair-font-size-x-small);
    }
  }

  .text-pair__subtitle {
    &.text-pair__subtitle--meta {
      color: var(--gc-text-meta);
    }

    &.text-pair__subtitle--x-small {
      --__op-text-pair-subtitle-font-size: var(--_op-text-pair-font-size-x-small);
    }
  }

  h1 {
    margin: 0;
  }
}

.text-pairs-separated {
  --__text-pair-separator-space: var(--op-space-x-small);
  --__text-pair-gap: var(--op-space-3x-small);

  display: flex;
  gap: var(--__text-pair-separator-space);
  
  &.text-pairs-separated--small {
    --__text-pair-separator-space: var(--op-space-2x-small)
  }

  & > ::after {
    color: var(--gc-text-meta);
    content: '|';
    padding-left: calc(var(--__text-pair-separator-space) - var(--__text-pair-gap));
  }

  & > :last-child::after {
    visibility: hidden;
  }
}
