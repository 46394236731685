@page landscape {
  size: 11in 8.5in;
}

@mixin print {
  body.app-body {
    overflow: visible;
    display: block;
    background-color: transparent;
  }

  @page {
    margin: 0;
  }

  .app__content {
    padding: var(--letter-padding) !important;
    background-color: transparent;

    &:has(.side-column) {
      max-width: unset;
    }
  }

  br {
    content: "";
    margin: .8em !important;
    display: block;
    font-size: 24% !important;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  .show-print {
    display: flex !important;
  }

  .show-print-block {
    display: block !important;
  }

  .print-landscape {
    page: landscape;
    .app__content {
      max-width: 100%;
    }

    .landscape-scale {
      zoom: 0.77; // Letter size difference, 8.5in / 11in = 77%
    }
  }

  .badge-wrapper {
    print-color-adjust: exact;
  }

  .copyright-report-wrapper {
    justify-content: flex-start;
  }

  .copyright {
    width: 100%;
  }

  %styled-container {
    padding: 0;
    box-shadow: none;
  }

  article {
    margin-inline: 32px;

    .report {
      .report__body {
        --__report-body-font-size: var(--op-font-small);

        &.report__body-medium {
          --__report-body-font-size: 13.3px;
        }

        &.report__body-large {
          --__report-body-font-size: 12.8px;
        }

        &.report__body-x-large {
          --__report-body-font-size: 0.129in; // ~ 12.3px; Using inches to keep it more consistent when printing
        }

        // Top and bottom reports have a lot of line breaks, so make these thinner
        &.report__body-top-bottom {
          br {
            margin: .35em !important;
          }
        }

        .trix-content {
          padding-bottom: 0;

          * {
            font-size: var(--__report-body-font-size) !important;
            line-height: var(--op-line-height-base) !important;
          }
        }
      }

      .report__title {
        color: var(--op-color-primary-original);
        text-align: center;
        font-feature-settings: 'cv13' on, 'cv06' on, 'ss07' on;
        font-family: var(--op-font-family);
        font-size: var(--op-font-2x-large);
        font-weight: var(--gc-font-weight-bolder);
        line-height: var(--op-line-height-dense);
        letter-spacing: -0.09px;
      }

      .report__cap-line {
        font-size: var(--gc-font-smallest) !important;
        line-height: var(--op-line-height-dense) !important;


        font-weight: var(---op-font-weight-semi-bold);
        padding: 4px 0 0 10px;
        border-top-width: 0px;
      }
    }
  }

  report-breadcrumbs {
    display: none;
  }
}

@media print {
  @include print;
}

.print-preview {
  @include print;
}
