:root {
  --gc-company-data-sheet-height: 400px;
}

.flexible-layout-area.company-layout-area {
  @media screen and (max-width: $breakpoint-x-small) {
    flex-direction: column-reverse;
  }
}

.layout-area-three-col {
  display: flex;
  gap: var(--op-space-x-large);

  @media screen and (max-width: $breakpoint-x-small) {
    gap: var(--op-space-medium);
  }

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;
  }

  .credit-score-container {    
    .company-credit-score--rationale {
      @media screen and (min-width: $breakpoint-x-small) {
        font-size: var(--gc-font-small-minus-one);
      }
    }
  }

  .analyst__email-box {
    justify-content: start;

    .analyst__email-button {
      width: auto;
      color: var(--gc-link-primary-color);

      @media screen and (width < $breakpoint-x-small) {
        width: 100%;
      }
    }
  }

  .items-heading {
    font-weight: var(--op-font-weight-semi-bold);
  }

  .col {
    display: flex;
    flex-direction: column;
    gap: var(--op-space-medium);

    @media screen and (min-width: $breakpoint-x-small) {
      gap: var(--op-space-x-large);
    }

    &.tablet-only {
      @extend .tablet-only;
    }
    
    &.except-tablet {
      @extend .except-tablet;
    }

    &.col--quarter {
      flex: 1;

      @media screen and (min-width: $breakpoint-x-small) and (max-width: $breakpoint-medium) {
        flex: 2;
      }

      @media screen and (max-width: $breakpoint-x-small) {
        > .credit-score-container {
          order: 1;
        }
  
        > .company-overview-container {
          order: 0;
        }
      }
    }

    &.col--half {
      flex: 2;
    }
  }
}

.company__underlying-view {
  color: var(--gc-color-dark-text);
}

.company__data-sheet {
  height: var(--gc-company-data-sheet-height);

  &__container {
    @extend %styled-container;
    container: main-container / inline-size;


  }

  &__button {
    background-color: var(--op-color-primary-minus-six);
    color: var(--op-color-primary-on-minus-six);
  }

  &__button-bar {
    background-color: var(--gc-color-background);
    border-radius: var(--gc-radius-default);
    padding: var(--op-space-x-small);
  }
}

.company__container-overview {
  display: flex;
  align-items: center;
  gap: var(--op-space-medium);

  .company__photo-wrapper {
    max-width: 70px;
  }
}

.ticker {
  color: var(--op-color-neutral-on-plus-max-alt);
}

.score {
  @include credit-score;
}

.company__underlying-view {
  @include report-body;
}

.underlying-view {
  @include aside-text;
}

.upgrade-downgrade {
  .upgrade-downgrade__date {
    display: flex;
    gap: var(--op-space-small);
    font-size: var(--op-font-x-small);
    align-items: center;
    color: var(--op-color-neutral-on-plus-max-alt);
    white-space: nowrap;
  }

  .upgrade {
    color: var(--op-color-alerts-notice-minus-one);
  }

  .downgrade {
    color: var(--op-color-alerts-danger-base);
  }
}

table.table.company-table {
  @media screen and (max-width: $breakpoint-x-small) {

    // Adjust the table--disintegrate-mobile to use flex-wrap on the row so that sector and industry can be inline
    tr {
      flex-direction: row;
      flex-wrap: wrap;
    }

    td.company-table__date {
      --__table-disintegrate-column-order: 0;
      width: 100%;

      a {
        @include meta-text;
        font-size: var(--gc-font-small-minus-one);
        font-weight: var(--op-font-weight-normal);
        line-height: var(--op-line-height-densest);
        text-decoration: none;
      }
    }

    .company-table__name {
      --__table-disintegrate-column-order: 1;
      width: 100%;

      a:not(.link) {
        color: var(--gc-text-default);
        font-size: var(--op-font-x-large);
        font-weight: var(--op-font-weight-bold);
        line-height: var(--op-line-height-dense);
        letter-spacing: 0.2px;
      }

      .ticker {
        display: none;
      }
    }

    .company-table__sector {
      --__table-disintegrate-column-order: 2;

      color: var(--gc-text-default);
      font-size: var(--op-font-medium);
      font-weight: var(--op-font-weight-medium);
      line-height: var(--op-line-height-base);

      &::after {
        padding-left: var(--op-space-2x-small);
        content: '/'
      }
    }

    .company-table__industry {
      --__table-disintegrate-column-order: 3;

      color: var(--gc-text-default);
      font-size: var(--op-font-medium);
      font-weight: var(--op-font-weight-medium);
      line-height: var(--op-line-height-base);
    }
  }
}

.company__logo-preview {
  &.company__logo-preview--dark {
    img {
      background: var(--gc-color-background-dark);
    }
  }

  &.company__logo-preview--light {
    img {
      background: var(--gc-color-background-light);
    }
  }

  img {
    max-width: 150px;
    height: auto;
  }
}
