sl-details {
  &::part(base) {
    border: 0;
  }

  &::part(content) {
    padding: 0;
    padding-top: var(--op-space-x-small);
  }

  &::part(header) {
    padding: 0;
  }

  &::part(summary) {
    flex: 0;
  }

  &::part(summary-icon) {
    rotate: 0deg !important;
  }
}
