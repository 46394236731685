sl-menu {
  --sl-panel-background-color: var(--gc-color-container-background);
  --sl-panel-border-color: var(--op-color-border);
  --sl-border-radius-medium: var(--gc-radius-default);
  --sl-spacing-x-small: var(--op-space-x-small);

  box-shadow: var(--op-shadow-medium);

  color: var(--op-color-neutral-on-plus-max);

  &[padding-variant="none"] {
    padding: 0;
  }

  &[padding-variant="none-top"] {
    padding-top: 0;
  }
}
