@mixin badge-notification {
  --gc-badge-notification-left: calc(5 * var(--op-size-unit)); // 20px
  position: absolute;
  top: 0;
  left: var(--gc-badge-notification-left);
  box-shadow: none;
  pointer-events: none;
}

%badge-global {
  border-radius: var(--gc-radius-default);
  font-family: var(--op-font-family-mono);
  font-size: var(--op-font-small);
  font-weight: var(--op-font-weight-semi-bold);
  gap: var(--op-space-3x-small);
  line-height: var(--op-line-height-densest);

  .material-symbols-outlined {
    font-size: var(--op-font-medium);
  }

  &.badge--notification-right-navbar {
    @include badge-notification;

    font-size: var(--op-font-x-small);
    min-width: 15px;
    padding: 1px 2px;
    gap: 0;
    align-items: center;

    .notification__count--plus {
      margin-left: -1px;
      margin-right: -2px;

      &::after {
        font-family: "Material Symbols Outlined";
        font-size: 9px;
        content: "\e145"; // icon 'add'
      }
    }
  }

  &.badge--selectable {
    user-select: text;
  }

  &.badge--large {
    font-size: var(--op-font-large);

    .material-symbols-outlined {
      font-size: var(--op-font-medium);
    }
  }

  &.badge--content-type {
    font-size: var(--op-font-x-small);
    background-color: var(--op-color-neutral-plus-six);
    color: var(--op-color-neutral-on-plus-six);

    .material-symbols-outlined {
      font-size: var(--op-font-small);
    }
  }

  &.badge--upgrade {
    background-color: var(--op-color-alerts-notice-plus-seven);
    color: var(--op-color-alerts-notice-on-plus-seven-alt);
  }

  &.badge--downgrade {
    background-color: var(--op-color-alerts-danger-plus-seven);
    color: var(--op-color-alerts-danger-on-plus-seven-alt);
  }

  &.badge--credit-score {
    &.deteriorating {
      background-color: var(--op-color-alerts-danger-plus-eight);
      color: var(--op-color-alerts-danger-on-plus-seven-alt);
      border: var(--op-border-width) solid var(--op-color-alerts-danger-minus-two);
    }

    &.stable {
      color: var(--op-color-primary-on-plus-eight);
      background-color: var(--op-color-primary-plus-six);
      border: var(--op-border-width) solid var(--op-color-primary-minus-one);
    }

    &.improving {
      color: var(--gc-color-secondary-on-plus-five);
      background-color: var(--gc-color-secondary-plus-five);
      border: var(--op-border-width) solid var(--gc-color-secondary-base);
    }
  }

  &.badge--buy,
  &.badge--buy_on_weakness,
  &.badge--outperform_ltd_upside,
  &.badge--outperform {
    color: var(--gc-color-secondary-on-plus-five);
    background-color: var(--gc-color-secondary-plus-five);
    border: var(--op-border-width) solid var(--gc-color-secondary-base);
  }

  &.badge--sell,
  &.badge--sell_on_strength,
  &.badge--underperform,
  &.badge--underperform_ltd_downside {
    background-color: var(--op-color-alerts-danger-plus-eight);
    color: var(--op-color-alerts-danger-on-plus-seven-alt);
    border: var(--op-border-width) solid var(--op-color-alerts-danger-minus-two);
  }

  &.badge--type {
    padding: var(--op-space-3x-small) 0;
    background-color: transparent;
    color: var(--op-color-alerts-neutral-base);
  }

  &.badge--comment-type {
    background-color: transparent;
    font-size: var(--op-font-x-small);
    font-weight: var(--op-font-weight-normal);
    padding: var(--op-space-3x-small) 0;
    color: var(--op-color-alerts-neutral-on-max);
  }

  &.badge--link {
    background-color: transparent;
    font-size: var(--op-font-small);
    text-decoration: underline;
    font-weight: var(--op-font-weight-bold);
    padding: var(--op-space-3x-small) 0;
    color: var(--op-color-primary-plus-one);
  }

  &.badge--pending {
    background-color: var(--op-color-alerts-info-base);
    color: var(--op-color-neutral-on-base);
  }

  &.badge--unassigned {
    background-color: var(--op-color-alerts-warning-plus-one);
    color: var(--op-color-alerts-warning-on-plus-one);
  }

  &.badge--closed {
    background-color: var(--op-color-alerts-notice-minus-one);
    color: var(--op-color-alerts-notice-on-minus-one);
  }

  &.badge--status {
    text-transform: none;

    &.badge--pending {
      background-color: var(--op-color-alerts-warning-plus-one);
      color: var(--op-color-alerts-warning-on-plus-one);
    }

    &.badge--approved {
      background-color: var(--op-color-alerts-notice-minus-one);
      color: var(--op-color-alerts-notice-on-minus-one);
    }

    &.badge--released {
      background-color: var(--op-color-primary-minus-one);
      color: var(--op-color-primary-on-minus-one);
    }
  }

  &.badge--results {
    background-color: var(--gc-color-secondary-original);
    color: var(--gc-color-navbar-background);
    text-transform: none;
  }
}
