sl-dropdown {
  --sl-z-index-dropdown: calc(var(--op-z-index-header) + 1);

  // Prevent a page jump before Flatpickr CSS loads
  &:not([open]) {
    .menu {
      display: none;
    }
  }
}

sl-dropdown.sl-dropdown--full-width {
  container-type: inline-size;
  width: 100%;

  sl-menu, gc-menu {
    width: 100cqw;

    sl-menu-item, gc-menu-item {
      width: 100%;
    }
  }
}
