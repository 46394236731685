.mt__copyright,
.mt__date {
  color: var(--gc-text-meta);
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-densest);
  margin-block: 0;
  flex: 1;
  font-size: var(--gc-font-small-minus-one);
  line-height: var(--op-line-height-dense);
}

.mt__date {
  white-space: nowrap;
  text-align: right;
}
