%btn-global {
  --_op-btn-padding-small: 0 var(--op-space-small);
  --_op-btn-padding-medium: 0 var(--op-space-medium);
  --_op-btn-padding-large: 0 var(--op-space-medium);
  --_op-btn-font-large: var(--op-font-medium);

  border-radius: var(--gc-radius-default);

  line-height: var(--op-line-height-densest);
  font-weight: var(--op-font-weight-medium);
  transition: var(--gc-transition-btn-hover);

  &.btn--desktop-only {
    @media screen and (max-width: $breakpoint-x-small) {
      --_op-btn-padding-small: 0;
      --_op-btn-padding-medium: 0;
      --_op-btn-padding-large: 0;

      background-color: unset;
      color: unset;
      box-shadow: unset;
      min-height: unset;
    }
  }
  &.btn--semi-bold {
    font-weight: var(--op-font-weight-semi-bold);
  }
  &.btn--bold {
    font-weight: var(--op-font-weight-bold);
  }
}

.btn {
  // Default state, and when we're fake hovering on mobile
  &,
  &:hover:not(:active) {
    background-color: var(--op-color-neutral-plus-max);
    color: var(--op-color-neutral-on-plus-max);
    box-shadow: var(--op-border-all) var(--gc-btn-border);
  }

  // When we're truly hovering over the element or it's being clicked
  &:active {
    background-color: var(--gc-btn-primary-hover-background);
    color: var(--gc-btn-primary-hover-text);
    box-shadow: var(--op-border-all) var(--gc-btn-primary-hover-border);
  }
  @media(hover: hover) {
    &:hover,
    &:hover:not(:active) {
      background-color: var(--gc-btn-primary-hover-background);
      color: var(--gc-btn-primary-hover-text);
      box-shadow: var(--op-border-all) var(--gc-btn-primary-hover-border);
    }
  }

  &.btn--no-border {
    // Default state, and when we're fake hovering on mobile
    &,
    &:hover:not(:active) {
      background-color: var(--gc-btn--no-border-background);
      color: var(--gc-btn--no-border-text);
      box-shadow: inset var(--op-border-all) var(--gc-btn--no-border-border);
    }

    // When we're truly hovering over the element or it's being clicked
    &:active {
      background: var(--gc-btn--no-border-hover-background);
      color: var(--gc-btn--no-border-hover-text);
      box-shadow: inset var(--op-border-all) var(--gc-btn--no-border-hover-border);
    }
    @media(hover: hover) {
      &:hover,
      &:hover:not(:active) {
        background: var(--gc-btn--no-border-hover-background);
        color: var(--gc-btn--no-border-hover-text);
        box-shadow: inset var(--op-border-all) var(--gc-btn--no-border-hover-border);
      }
    }

    &.btn--active {
      background-color: var(--gc-btn-primary-hover-background);
      color: var(--gc-btn-primary-hover-text);
      box-shadow: var(--op-border-all) var(--gc-btn-primary-hover-border);
    }
  }

  &.btn--pagination-divider {
    --__op-btn-padding: var(--op-space-3x-small);
  }

  &.btn--pagination-item {
    --__op-btn-height: var(--_op-btn-height-small);
    --__op-btn-font-size: var(--_op-btn-font-small);
    --__op-btn-padding: var(--op-space-x-small);

    &.btn--pagination-icon {
      --__op-btn-padding: var(--op-space-x-small) var(--op-space-3x-small);
    }
  }
}


.btn-primary {
  // Default state, and when we're fake hovering on mobile
  &,
  &:hover:not(:active) {
    background-color: var(--gc-btn-primary-background);
    color: var(--gc-btn-primary-text);
    box-shadow: var(--op-border-all) var(--gc-btn-primary-border);
  }

  // When we're truly hovering over the element or it's being clicked
  &:active {
    background-color: var(--gc-btn-primary-hover-background);
    color: var(--gc-btn-primary-hover-text);
    box-shadow: var(--op-border-all) var(--gc-btn-primary-hover-border);
  }
  @media(hover: hover) {
    &:hover,
    &:hover:not(:active) {
      background-color: var(--gc-btn-primary-hover-background);
      color: var(--gc-btn-primary-hover-text);
      box-shadow: var(--op-border-all) var(--gc-btn-primary-hover-border);
    }
  }

  // Focus State
  &:focus,
  &:focus-within,
  &:focus-visible {
    background-color: var(--gc-btn-focus-background);
    color: var(--gc-btn-focus-text);
    box-shadow: var(--op-input-focus-primary);
  }
  // Borderless State
  &.btn--no-border {
    color: var(--gc-btn-primary--no-border-text);

    // Borderless + Active State
    &.btn--active {
      background-color: var(--gc-btn--no-border-active-background);
      color: var(--gc-btn--no-border-active-text);
      box-shadow: var(--op-border-all) var(--gc-btn--no-border-active-border);
    }

    // Borderless + Hover State
    &:hover {
      background-color: var(--gc-btn-primary--no-border-hover-background);
      box-shadow: var(--op-border-all) var(--gc-btn-primary--no-border-hover-border);
      color: var(--gc-btn-primary--no-border-hover-text);
    }
  }
}

.btn-delete {
  &.btn--no-border {
    background-color: transparent;
    color: var(--op-color-alerts-danger-base);
    box-shadow: none;
    &:hover {
      color: var(--op-color-alerts-danger-on-base);
      background-color: var(--op-color-alerts-danger-base);
    }
  }
}

// New button component for navbar buttons following the Optics pattern
.btn-navbar {
  @extend %btn-global;
  --_op-btn-padding-large: 0 var(--op-space-small);
  --_op-btn-font-large: var(--op-font-medium);
  background-color: transparent;
  color: var(--_op-navbar-text-color);
  fill: var(--_op-navbar-text-color);
  font-weight: var(--op-font-weight-semi-bold);
  box-shadow: var(--op-border-all) var(--gc-color-navbar-text);

  svg {
    path {
      fill: var(--_op-navbar-text-color);
    }
  }

  &:hover {
    background-color: transparent;
    color: var(--gc-color-secondary-original);
    fill: var(--gc-color-secondary-original);
    box-shadow: var(--op-border-all) var(--gc-color-secondary-original);
    svg {
      path {
        fill: var(--gc-color-secondary-original);
      }
    }
  }

  // Focus State
  &:focus-visible {
    background-color: var(--gc-btn-focus-background);
    color: var(--gc-btn-focus-text);
    fill: var(--gc-btn-focus-text);
    box-shadow: var(--op-input-focus-primary);
  }

  &.btn--active {
    background-color: transparent;
    color: var(--gc-color-secondary-original);
    fill: var(--gc-color-secondary-original);
    box-shadow: none;

    &:focus-visible {
      background-color: var(--gc-btn-focus-background);
      color: var(--gc-btn-focus-text);
      fill: var(--gc-btn-focus-text);
      box-shadow: var(--op-input-focus-primary);
    }
  }
  &.btn--no-border {
    box-shadow: none;
    // Focus State
    &:focus-visible {
      background-color: var(--gc-btn-focus-background);
      color: var(--gc-btn-focus-text);
      fill: var(--gc-btn-focus-text);
      box-shadow: var(--op-input-focus-primary);
    }
  }
}

// Used by blazer
.btn-success {
  @extend %btn-global;
  background-color: var(--op-color-primary-plus-one);
  color: var(--op-color-primary-on-plus-one);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-one);

  &:hover {
    background-color: var(--op-color-primary-minus-one);
    color: var(--op-color-primary-on-minus-one);
    box-shadow: inset var(--op-border-all) var(--op-color-primary-minus-one);
  }

  // Focus State
  &:focus,
  &:focus-within,
  &:focus-visible {
    background-color: var(--op-color-primary-base);
    color: var(--op-color-primary-on-base);
  }
}

.btn-wrapper {
  justify-content: flex-start;
  display: flex;
  gap: var(--op-space-small);

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: row;
    gap: var(--op-space-medium);

    .btn {
      align-self: flex-start;
    }
  }
}

details > summary {
  list-style: none;

  &::marker {
    display: none;
  }
}

.btn-see-previous {
  --_op-btn-font-large: var(--op-font-x-small);
  @extend %btn-global;
  font-weight: var(--op-font-weight-bold);
  flex-direction: column;
  gap: var(--op-space-small);
  align-items: flex-end;
  color: var(--gc-btn--no-border-text);
  padding: var(--op-space-x-small);
  line-height: var(--op-line-height-densest);

  // Hover State
  &:hover {
    color: var(--gc-link-primary-color);
    path {
      fill: var(--gc-link-primary-color);
    }
  }

  // Focus State
  &:focus-visible {
    background-color: var(--gc-btn-focus-background);
    color: var(--gc-btn-focus-text);
    box-shadow: var(--op-input-focus-primary);
    path {
      fill: var(--gc-btn-focus-text);
    }
  }

  &.btn-see-previous--left {
    align-items: flex-start;
    margin-right: auto;
  }
}

.btn.btn--medium.btn--medium-ignoring-mobile-standards {
  --__op-btn-height: var(--_op-btn-height-medium);
  --__op-btn-font-size: var(--_op-btn-font-medium);
  --__op-btn-padding: var(--_op-btn-padding-medium);
}
