%modal-wrapper-global {
  @media screen and (max-width: $breakpoint-small) {
    padding: var(--op-space-x-large);
  }
}

%modal-global {
  border-radius: var(--gc-radius-default);
}

// seems like this should not be necessary, but without it, the confirm dialog is breifly visible on page load
.modal-wrapper:not(.modal-wrapper--active) {
  visibility: hidden;
  opacity: 0;
}

.modal.confirm-title {
  text-align: left;
}

.modal-wrapper--complimentary {
  .modal-wrapper__backdrop {
    --_op-modal-backdrop-active-opacity: var(--op-opacity-full);
    background: hsla(227, 57%, 15%, 0.3);
    backdrop-filter: blur(var(--op-space-3x-small));
  }
}

.modal.modal-data-sheet {
  --_gc-modal-total-height: calc(100vh - 50px);
  --_gc-modal-header-height: 61px;
  --_gc-modal-footer-height: 51px;

  --_op-modal-width: 1150px;
  --_op-modal-max-height: calc(var(--_gc-modal-total-height) - var(--_gc-modal-header-height) - var(--_gc-modal-footer-height));

  width: 100%;
  max-height: var(--_gc-modal-total-height);
  max-width: var(--_op-modal-width);

  .modal__body {
    padding: 0;

    .data-sheet-preview__wrapper {
      max-height: var(--_op-modal-max-height);
      max-width: var(--_op-modal-width);
      width: 100%;

      .data-sheet-preview__pdf {
        max-width: var(--_op-modal-width);
        width: 100%;
      }
    }

  }
}

.modal.complimentary-modal {
  --_op-modal-width: 100%;
  max-width: calc(var(--report-body-width-modifier) * var(--op-size-unit));
  text-align: center;
  padding: var(--op-space-2x-large);
  background-color: var(--op-color-neutral-plus-seven);
  color: var(--op-color-neutral-on-plus-seven);

  .modal__header {
    justify-content: center;
    padding: 0;
    font-family: var(--op-font-family);
    font-weight: var(--op-font-weight-semi-bold);
    font-size: var(--op-font-3x-large);
  }

  .modal__body {
    box-shadow: none;
    padding: var(--op-space-large) 0;
  }

  .modal__footer {
    justify-content: center;
  }

  .product-name {
    color: var(--op-color-neutral-on-plus-seven-alt);
    font-weight: var(--op-font-weight-bold);
  }
}

.modal.modal--letter {
  width: calc(var(--letter-width) + 0.5in);
  height: calc(var(--letter-height) + 0.5in);
  max-height: 95%;
  max-width: 100%;

  & .modal__body {
    max-height: 85%;
  }
}

.modal.modal-publisher-notes {
  --_op-modal-width: calc(152 * var(--op-size-unit));

  .modal__header {
    box-shadow: inset 0 var(--gc-brand-top-width) 0 0 var(--gc-color-secondary-original);
    padding-block: var(--op-space-2x-large);
  }

  .modal__body {
    padding-block: var(--op-space-3x-large);
    padding-inline: var(--op-space-x-large);
  }

  .modal__header,
  .modal__body {
    display: flex;
    flex-direction: column;
    gap: var(--op-space-medium);
  }

  .modal__footer {
    padding-inline: var(--op-space-x-large);
    padding-block: var(--op-space-small);
  }
}

// When there's a tom-select input at the bottom of a modal, it gets cut off, so add this modifier to allow it to overflow
.modal.modal__tom-select {
  contain: inline-size;

  .modal__body {
    overflow-y: unset;
  }
}

.modal-wrapper:has(.modal-search) {
  align-items: self-start;
  padding-top: var(--op-space-4x-large);
  padding-inline: 0;
}

.modal.modal-search {
  contain: inline-size;
  background-color: var(--op-color-neutral-plus-max);

  .modal__header {
    padding: 0;
  }

  .modal__body {
    --_op-modal-max-height: 700px;

    box-shadow: none;
    padding: var(--op-space-large) 0 0 0;
    display: flex;
    flex-direction: column;
    gap: var(--op-space-large);
  }

  .modal__footer {
    padding: 0;
  }
}

.modal.modal-newswires {
  contain: inline-size;
  background-color: var(--op-color-neutral-plus-max);

  .modal__header {
    padding-inline: var(--op-space-large);
    padding-bottom: 0;
  }

  .modal__body {
    --_op-modal-max-height: 700px;

    box-shadow: none;
    padding: var(--op-space-large);
    display: flex;
    flex-direction: column;
    gap: var(--op-space-large);
  }

  .modal__footer {
    padding: 0;
  }
}
